import { NgStyle } from "@angular/common";
import { Directive, Input, booleanAttribute } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";

@Directive({
    standalone: true,
    selector: '[saMatTooltip]',
    exportAs: 'saMatTooltip'
})
export class StandaloneMatTooltip extends MatTooltip { }


@Directive({
    standalone: true,
    selector: '[blockedFeatureTooltip]',
    hostDirectives: [
        {
            directive: StandaloneMatTooltip,
            inputs: ['matTooltip: message', 'matTooltipDisabled: disabled', 'matTooltipClass: tooltipClass']
        },
        {
            directive: NgStyle,
            inputs: ['ngStyle: ngStyle']
        }
    ]
})
export class BlockedFeatureTooltipDirective {

    @Input({ alias: 'blockedFeatureTooltip', transform: booleanAttribute }) set enable(enable: boolean) {
        this.standaloneMatTooltip.disabled = !enable;
        this.standaloneMatTooltip.message = enable ? 'This feature is not available with the current Bundle.\nSee the documentation for more details.' : null;
    };

    constructor(
        private standaloneMatTooltip: StandaloneMatTooltip,
        private ngStyle: NgStyle
    ) {
        this.standaloneMatTooltip.tooltipClass = 'blocked-feature-tooltip';
        this.ngStyle.ngStyle = { 'pointer-events': 'all' }; // needed to show the tooltip over disabled buttons
    }
}